var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.reservations.length > 0)?_c('b-table',{attrs:{"items":_vm.reservations,"fields":_vm.fields,"sort-by":_vm.ReservationID,"sort-desc":_vm.sortDesc,"responsive":""},on:{"update:sortBy":function($event){_vm.ReservationID=$event},"update:sort-by":function($event){_vm.ReservationID=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(Status)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm.reservationStatus(data.item.Status, data.item.WarehouseID)))])]}},{key:"cell(ReservationID)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: 'employee-reservation',
          params: { voucher: data.item.Voucher }
        }}},[_vm._v("#"+_vm._s(data.item.ReservationOrderID))])]}},{key:"cell(DateTimeBusinessStart)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.DateTimeBusinessStart))+" ")]}},{key:"cell(DateTimeExpectedEnd)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.DateTimeExpectedEnd))+" ")]}},{key:"cell(ReportID)",fn:function(data){return [(data.item.ReportID)?_c('span',{staticClass:"text-primary",staticStyle:{"cursor":"pointer"},attrs:{"variant":"link"},on:{"click":function($event){return _vm.getReport({
            reportID: data.item.ReportID,
            reservationOrderID: data.item.ReservationOrderID,
            reportFileName: data.item.ReportFileName,
            attachmentToken: data.item.AttachmentToken
          })}}},[_vm._v(_vm._s(data.item.ReportFileName))]):_vm._e()]}},{key:"cell(ContactFirstName)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.ContactFirstName)+" "+_vm._s(data.item.ContactLastName)+" ")]}}],null,false,1695596264)}):_c('p',[_vm._v(" U heeft nog geen reserveringen om te bekijken. Wanneer u een nieuwe reservering aanmaakt zal deze hier worden getoond. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }